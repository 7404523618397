%hexagon-pattern-overlay {
  background: {
    image: url('/images/hexagon_pattern_ffffff.svg');
    position: calc(50% + 800px) -100px;
    repeat: no-repeat;
    size: 1910px 1304px;
  }
  content: '';
  display: block;
  height: 100%;
  mix-blend-mode: soft-light;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.site-name {
  z-index: 110;

  .logo {
    height: auto;
    margin: 0 $spacing-horizontal;
    max-width: 75%;
    object-fit: contain;
    width: 218px;
  }
}

.payoff {
  color: $color-white;
  font: {
    size: $font-size-base * 4;
    weight: $font-weight-regular;
  }
  line-height: $line-height-base * 3;
  margin: $spacing-vertical $spacing-horizontal;
  text-align: center;

  @media (min-width: $screen-md) {
    text-align: left;
  }
}

.page-front header {
  background: {
    // @TODO: add fallback code for browsers not supporting blend-mode?
    //   see: https://caniuse.com/#search=background-blend-mode
    //   SVG Filter - feColorMatrix?
    blend-mode: luminosity;
    color: $color-grey;
    image: linear-gradient($color-overlay-grey, $color-overlay-grey), image-set(
      url('/images/content/header_background_v2-lowres.avif') type("image/avif"),
      url('/images/content/header_background_v2-lowres.jpg') type("image/jpeg")
      );

    position: 50% 50%;
    repeat: no-repeat;
    size: cover;
  }

  @media (min-width: $screen-lg) {
    background: {
      image: linear-gradient($color-overlay-grey, $color-overlay-grey), image-set(
        url('/images/content/header_background_v2.avif') type("image/avif"),
        url('/images/content/header_background_v2.jpg') type("image/jpeg")
      );
    }
  }
  position: relative;

  &::after {
    background: {
      image: url('/images/hexagon_pattern_ffffff.svg');
      position: 0 -20%;
      repeat: no-repeat;
      size: 1910px 1304px;
    }
    content: '';
    display: block;
    //mix-blend-mode: soft-light;
  }

  @media (min-width: $screen-md) {
    &::after {
      background-position: 50px -25%;
    }
  }

  @media (min-width: $screen-lg) {
    &::after {
      background-position: 325px -20%;
    }
  }

  @media (min-width: $screen-xl) {
    &::after {
      background-position: 400px 4%;
    }
  }

  .site-name {
    background: {
      image: url('/images/hexagon_ffffff.svg');
      position: calc(100% + 8px) -286px;
      repeat: no-repeat;
      size: 400px auto;
    }
    width: 100%;
    z-index: 110;

    @media (min-width: $screen-md) {
      background-position: -205px -370px;
      background-size: 500px auto;
    }

    @media (min-width: $screen-lg) {
      background-position: calc(100% - 75px) -490px;
      background-size: 640px auto;
    }

    .logo {
      max-width: 75%;
      min-height: 100px;
    }
  }

  .nav-toggle-label,
  nav {
    z-index: 100;
  }
}

.page-content header,
.page-news header,
.page-news-item header {
  nav {
    z-index: 100;
  }

  &::after {
    @extend %hexagon-pattern-overlay;
  }
}

footer {
  position: relative;

  &::after {
    @extend %hexagon-pattern-overlay;
  }

  nav {
    z-index: 50;
  }

  .site-name {
    display: block;
    margin: $spacing-vertical 0 0 -80px;
    z-index: 50;

    @media (min-width: $screen-md) {
      margin-left: 0;
    }

    .logo {
      max-width: 100%;
      width: 218px;
    }
  }
}
