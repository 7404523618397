@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: ($height / $width) * 100%;
    width: 100%;
  }

  > span {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

@mixin hexagon-clip() {
  clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
  -webkit-clip-path: polygon(50% 0%, 93% 25%, 93% 75%, 50% 100%, 7% 75%, 7% 25%);
  clip-path: url('#hexagon');
  -webkit-clip-path: url('#hexagon');
}

@mixin hexagon-pattern($element, $gap) {
  align-items: center;
  display: grid;
  grid-gap: 20px 0;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;

  @media (min-width: $screen-md) {
    grid-gap: $gap 0;
    justify-content: space-around;
  }

  #{$element} {
    grid-column: 1/5;
    padding: 0;
    text-align: center;

    &:nth-of-type(1) {
      grid-row: 1;
    }

    &:nth-of-type(2) {
      grid-row: 2;
    }

    &:nth-of-type(3) {
      grid-row: 3;
    }

    &:nth-of-type(4) {
      grid-row: 4;
    }

    &:nth-of-type(5) {
      grid-row: 5;
    }

    @media (min-width: $screen-md) {
      &:nth-of-type(1) {
        grid-column: 3 / span 2;
        grid-row: 2 / span 2;
        text-align: left;
      }

      &:nth-of-type(2) {
        grid-column: 2 / span 2;
        grid-row: 1 / span 2;
        text-align: left;
      }

      &:nth-of-type(3) {
        grid-column: 4 / span 2;
        grid-row: 1 / span 2;
        text-align: left;
      }

      &:nth-of-type(4) {
        grid-column: 1 / span 2;
        grid-row: 2 / span 2;
        text-align: center;
      }

      &:nth-of-type(5) {
        grid-column: 5 / span 2;
        grid-row: 2 / span 2;
        text-align: left;
      }

      &:nth-of-type(6) {
        grid-column: 2 / span 2;
        grid-row: 3 / span 2;
        text-align: center;
      }

      &:nth-of-type(7) {
        grid-column: 4 / span 2;
        grid-row: 3 / span 2;
        text-align: center;
      }
    }
  }
}
