%underline-brand {
  box-shadow: inset 0 -.2em 0 $color-brand-light;
  padding-bottom: .1em;
}

html,
body {
  color: $color-brand-dark;
  font: {
    family: $font-roboto;
    size: $font-size-base;
    weight: $font-weight-light;
  }
  line-height: $line-height-base;
}

a {
  color: $color-brand-dark;
  text-decoration: underline $color-brand-light;

  &:visited {
    color: $color-brand-dark;
    text-decoration: underline $color-brand-light;
  }

  &:active,
  &:hover {
    color: mix($color-brand-light, $color-brand-dark)
  }

  &.emphasis {
    @extend %underline-brand;
    text-decoration: none;
  }

  &.readmore {
    @extend %underline-brand;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.team a {
  box-shadow: none;

  &:hover,
  &:active {
    @extend %underline-brand;
    outline: none;
  }
}

.inverse a,
.inverse-light a,
.inverse-lighter a {
  color: $color-white;

  &:visited {
    color: $color-white;
  }

  &:active,
  &:hover {
    color: $color-brand-light;
  }
}

b,
strong {
  font: {
    weight: $font-weight-bold;
  }
}

i,
em {
  font: {
    style: italic;
  }
}

h1 {
  color: $color-brand-dark;
  font-size: $font-size-h1;
  font-weight: $font-weight-light;
  line-height: $line-height-h1;
  margin: 1rem 0 1.5em;
  max-width: 90%;

  &.accent {
    color: $color-brand-light;
    font-weight: $font-weight-bold;
  }
}

.page-news h1,
.page-news-item h1 {
  margin-bottom: 1rem;
}

h2 {
  color: $color-brand-dark;
  font-size: $font-size-h2;
  font-weight: $font-weight-light;
  line-height: $line-height-h2;
  margin: 1rem 0 1.5em;

  &.description {
    border-bottom: 1px solid $color-grey;
    color: $color-grey;
    font: {
      size: $font-size-h4;
      weight: $font-weight-light;
    }
    line-height: $line-height-h4;
    margin-bottom: $spacing-vertical;
    padding: $spacing-vertical $spacing-horizontal;
    position: relative;

    &::after {
      bottom: .25em;
      content: '↘︎';
      font-size: $font-size-base;
      padding-left: .15em;
      position: absolute;
    }
  }

  &.hexagon,
  &.hexagon-light {
    @include aspect-ratio(1, 1);
    @include hexagon-clip;
    align-items: center;
    align-self: start;
    color: $color-white;
    display: flex;
    justify-content: center;
    margin: 0 0 $spacing-vertical;
    max-width: 100%;
    padding: 0;
    text-align: center;

    span {
      overflow-wrap: break-word;
      word-wrap: break-word;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      bottom: auto;
      left: auto;
      max-width: 80%;
      position: absolute;
      right: auto;
      top: auto;
    }
  }

  &.hexagon {
    background-color: $color-grey;
  }

  &.hexagon-light {
    background-color: $color-grey-lighter;
    color: $color-white;
  }
}

.inverse h2,
.inverse-light h2,
.inverse-lighter h2 {
  color: $color-white;

  &.hexagon {
    background-color: $color-white;
    color: $color-brand-dark;
  }

}

.inverse-lighter h2 {
  &.hexagon-light {
    background-color: $color-grey-dark;
  }
}


.page-news,
.page-news-item {
  h2 {
    margin-bottom: 1rem;
  }

  .info h2 {
    margin: .25em 0;
  }

  .info .author {
    margin: .25em 0 2em;
  }

  h3 {
    margin-bottom: 0;
  }

  p {
    margin: 0.5rem 0;
    + p {
      margin-top: 1rem 0 1rem;
    }
  }

  ul,
  ol {
    margin: 0.5rem 0;
  }
  ul { 
    list-style-image: url('/images/swat_engineering_avatar-ul-li.svg'); 
  }

  a.inline-button {
    background-color: $color-grey;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: start;
    padding: 0 0 $spacing-vertical;
    min-width: 100%;
  }
  a.inline-button:hover {
    background-color: $color-grey-light;
  }
  img+small,
  picture+small { 
    display:block;
    line-height: 0.5em;
    margin-bottom: 1em;
  }
}

h3 {
  color: $color-brand-dark;
  font-size: $font-size-h3;
  font-weight: $font-weight-light;
  line-height: $line-height-h3;
  margin: 1rem 0 1.5em;
}

.inverse h3,
.inverse-light h3,
.inverse-lighter h3 {
  color: $color-white;
}

h4 {
  color: $color-brand-dark;
  font-size: $font-size-h4;
  font-weight: $font-weight-bold;
  line-height: $line-height-h4;
  margin: 1rem 0 0;
}

.inverse h4,
.inverse-light h4,
.inverse-lighter h4 {
  color: $color-white;
}

p {
  margin: 1rem 0 1.5em;

  &.intro {
    font-size: $font-size-h4;
    line-height: $line-height-h4;
  }

  &.description {
    font-style: italic;
  }
}

main ol,
main ul {
  padding-left: 18px;

  &.pattern {
    @include hexagon-pattern(li, 120px);
  }

  &.clean,
  &.pattern {
    list-style: none;
    padding-left: 0;

    li {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
    }
  }

  &.clean li {
    line-height: $line-height-h2;

    img {
      object-fit: contain;
    }
  }

  li {
    margin-bottom: 0.5em;
    padding-left: .5em;

    p {
      margin: 0;
    }
  }
}

cite,
q {
  font-style: normal;

  &::after {
    content: '”';
  }

  &::before {
    content: '“';
  }
}


ul.pattern {
  @include hexagon-pattern(li, 150px);
  li.hexagon {
    @include aspect-ratio(1, 1);
    @include hexagon-clip;
    align-items: center;
    align-self: start;
    background: $color-brand-dark;
    color: $color-white;
    display: flex;
    justify-content: center;
    margin: 0;
    max-width: 90%;
    min-width: 150px;
    padding: 0;
    text-align: center;

    &.darker {
      background: $color-grey-darker;
    }

    &.dark {
      background: $color-grey-dark;
    }

    &.light {
      background: $color-grey-light;
    }

    &.lighter {
      background: $color-grey-lighter;
    }

    &.accent {
      background: $color-brand-light;
    }

    span, a {
      bottom: auto;
      left: auto;
      max-width: 90%;
      padding: 5%;
      position: absolute;
      right: auto;
      text-align: center;
      top: auto;
    }
  }
}

dl {
  margin: 1rem 0 1.5em;

  &.pattern {
    @include hexagon-pattern(dt, 150px);
    @include hexagon-pattern(dd, 150px);
  }

  &.summary {
    display: grid;
    grid-gap: $spacing-vertical $spacing-horizontal;
    grid-template-columns: 1fr 2fr;
  }

  dt {
    font: {
      style: normal;
      weight: $font-weight-bold;
    }

    &.hexagon {
      @include aspect-ratio(1, 1);
      @include hexagon-clip;
      align-items: center;
      align-self: start;
      background: $color-brand-dark;
      color: $color-white;
      display: flex;
      justify-content: center;
      margin: 0;
      max-width: 90%;
      min-width: 150px;
      padding: 0;
      text-align: center;

      &.darker {
        background: $color-grey-darker;
      }

      &.dark {
        background: $color-grey-dark;
      }

      &.light {
        background: $color-grey-light;
      }

      &.lighter {
        background: $color-grey-lighter;
      }

      &.accent {
        background: $color-brand-light;
      }

      span {
        bottom: auto;
        left: auto;
        max-width: 90%;
        padding: 5%;
        position: absolute;
        right: auto;
        text-align: center;
        top: auto;
      }
    }
  }

  dd {
    font: {
      style: normal;
      weight: $font-weight-light;
    }
    margin: 0 0 $spacing-vertical;
    padding-left: 0;
  }

  .hexagon + dd {
    @include aspect-ratio(1, 1);
    @include hexagon-clip;
    align-items: center;
    align-self: start;
    background: $color-brand-dark;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    max-width: 90%;
    min-width: 150px;
    opacity: 0;
    padding: 0;
    position: relative;
    text-align: center;
    transition: opacity 250ms ease-in-out;
    width: 100%;

    > span {
      @include hexagon-clip;
      align-items: center;
      background: $color-white;
      bottom: auto;
      content: '';
      display: flex;
      height: calc(100% - 3px);
      justify-content: center;
      left: auto;
      margin: auto 0;
      padding: 10%;
      position: absolute;
      right: auto;
      text-align: center;
      top: auto;
      width: calc(100% - 3px);
    }
  }

  .hexagon + dd:focus,
  .hexagon + dd:hover,
  .hexagon:focus + dd,
  .hexagon:hover + dd {
    opacity: 1;
    outline: none;
  }
}

.inline-icon {
  height: $line-height-base;
  vertical-align: bottom;
}

.hexagon-wrapper {
  display: flex;
  > img {
    margin: auto auto;
  }
}

.code {
  // based on lovelayce style in chroma
  // https://github.com/alecthomas/chroma/blob/master/styles/lovelace.xml
  font-size: 0.85em;
  font-family: "Lucida Console", "Courier New", monospace;
  white-space: preserve nowrap;
  background-color: #fff;
  color: #7f7f7f;

  .keyword {
    color: #2838b0;
    font-style: italic;
  }

  .added, .deleted, .empty {
    display: inline-block;
    width: 100%;
    //color: #fff;
  }
  .added {
    background-color: transparentize(#388038, 0.5);
  }
  .deleted {
    background-color: transparentize(#c02828, 0.5);
  }
  .empty {
    background-color: transparentize(#a89028, 0.5);
  }
  .changed {
    background-color: transparentize(#289870, 0.5);
    padding: 0.4em;
    //color: #fefefe;
  }
  .string {
    color: #a848a8;
  }
  .number {
    color: #444444;
  }
  .identifier {
    color: #709030;
  }
  .variable {
    //color: #b04040;
  }
  .operator {
    color: #2838b0;
  }
}
